import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1271.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1271.000000) scale(0.100000,-0.100000)">
<path d="M0 12700 c0 -7 2140 -10 6400 -10 4260 0 6400 3 6400 10 0 7 -2140
10 -6400 10 -4260 0 -6400 -3 -6400 -10z"/>
<path d="M3610 9735 c-7 -8 -16 -13 -20 -10 -4 2 -8 -17 -9 -43 0 -26 -6 -62
-12 -79 -11 -33 -33 -44 -42 -20 -3 6 -6 0 -6 -15 -1 -19 4 -28 14 -28 9 0 15
-9 15 -24 0 -14 -7 -31 -15 -40 -10 -10 -15 -35 -15 -83 0 -47 3 -63 10 -53 5
8 7 23 4 33 -3 9 -2 27 2 40 7 21 8 20 14 -8 4 -16 3 -40 -2 -53 -4 -13 -8
-44 -8 -68 0 -28 -4 -43 -10 -39 -6 4 -10 -8 -10 -30 0 -20 4 -34 9 -31 13 8
23 -185 10 -201 -6 -7 -9 -19 -6 -28 4 -8 2 -17 -3 -20 -6 -4 -10 -19 -10 -36
0 -27 -2 -29 -39 -29 -22 0 -41 5 -43 10 -2 6 -37 13 -78 16 -41 3 -79 10 -84
15 -12 11 -71 10 -106 -1 -14 -4 -19 -8 -12 -9 7 -1 11 -5 8 -10 -7 -11 17
-21 50 -21 19 0 24 -5 24 -25 0 -15 -6 -25 -14 -25 -22 0 -26 -34 -26 -237 l0
-193 -26 0 c-26 0 -26 -1 -20 -52 15 -133 19 -268 7 -268 -6 0 -11 -17 -11
-40 0 -23 -5 -40 -11 -40 -7 0 -10 -15 -7 -40 2 -22 0 -40 -4 -40 -5 0 -8 -17
-8 -38 l0 -38 -40 4 c-39 4 -40 3 -40 -27 l0 -32 49 7 c48 6 50 5 65 -25 16
-29 15 -31 -1 -32 -10 0 -13 -3 -6 -6 9 -3 7 -11 -7 -26 -14 -14 -17 -23 -9
-28 7 -4 4 -9 -10 -14 -12 -4 -28 -2 -35 4 -18 15 -46 3 -46 -20 0 -10 -7 -19
-15 -19 -8 0 -15 6 -15 14 0 15 -21 26 -51 26 -10 0 -19 4 -19 8 0 11 -68 32
-101 32 -13 0 -33 7 -43 15 -11 8 -32 15 -48 15 -15 0 -28 5 -28 10 0 6 -7 10
-15 10 -8 0 -23 5 -33 10 -32 19 -203 80 -223 80 -17 0 -18 -3 -9 -20 6 -11
23 -26 38 -34 27 -15 26 -15 -27 -16 -47 0 -52 -2 -46 -17 5 -10 10 -33 13
-50 2 -18 8 -33 13 -33 5 0 9 -9 9 -20 0 -11 7 -20 15 -20 25 0 14 -33 -15
-42 -8 -2 7 -11 33 -18 26 -8 47 -18 47 -22 0 -4 -17 -10 -37 -13 -48 -8 -270
-83 -311 -105 -9 -4 -44 -18 -77 -31 -73 -27 -185 -81 -296 -145 -153 -87
-159 -95 -115 -139 14 -14 37 -41 53 -62 28 -37 261 -275 318 -324 17 -14 64
-52 105 -83 41 -32 77 -62 78 -67 2 -5 8 -9 14 -9 6 0 25 -11 42 -23 32 -25
181 -118 251 -157 48 -28 128 -67 212 -105 34 -15 64 -34 67 -41 3 -7 -11 -20
-32 -30 -50 -23 -140 -90 -177 -131 -17 -18 -35 -33 -40 -33 -17 0 -265 -263
-272 -287 -3 -14 0 -30 6 -36 8 -8 11 -7 11 6 0 12 8 17 28 17 15 0 33 5 40
12 9 9 12 4 12 -26 0 -34 17 -66 35 -66 4 0 16 -12 28 -26 18 -23 25 -26 69
-22 41 4 52 2 61 -13 7 -11 20 -19 29 -19 13 0 18 -8 18 -25 0 -27 1 -29 45
-80 17 -20 28 -26 32 -17 7 18 83 16 83 -3 0 -8 5 -15 10 -15 6 0 10 -4 10 -9
0 -4 -24 -7 -52 -5 -29 1 -42 0 -28 -3 13 -3 31 -14 39 -25 11 -16 15 -17 22
-7 7 11 14 8 36 -14 24 -23 26 -29 13 -37 -13 -8 -12 -11 5 -24 18 -13 21 -13
27 5 7 19 38 17 38 -3 0 -13 26 -38 39 -38 6 0 11 -4 11 -10 0 -5 16 -10 35
-10 31 0 35 -3 35 -25 0 -22 4 -25 40 -25 33 0 40 -3 40 -19 0 -11 18 -37 40
-59 22 -21 40 -44 40 -50 0 -7 16 -12 40 -12 33 0 40 -3 40 -20 0 -16 7 -20
33 -21 17 -1 24 -3 15 -6 -9 -2 -15 -10 -12 -18 3 -7 14 -11 25 -8 10 3 19 0
19 -6 0 -6 -9 -11 -19 -11 -11 0 -23 -9 -26 -20 -3 -11 -13 -20 -21 -20 -8 0
-14 -5 -14 -11 0 -12 -40 -48 -53 -49 -5 0 -31 -21 -57 -48 -57 -56 -101 -92
-115 -92 -12 0 -101 -99 -128 -141 -16 -25 -17 -29 -4 -29 9 0 23 -7 32 -16 9
-9 19 -13 23 -10 3 4 0 11 -8 16 -23 14 -2 24 48 21 15 -1 22 -7 22 -20 0 -12
-8 -21 -22 -24 l-23 -4 23 -8 c12 -4 22 -11 22 -16 0 -5 32 -9 70 -9 43 0 70
-4 70 -11 0 -5 5 -7 10 -4 6 3 10 15 10 25 0 33 17 31 55 -5 20 -20 50 -39 68
-42 18 -3 34 -9 37 -13 3 -4 50 -12 105 -19 83 -10 103 -15 117 -34 15 -19 18
-19 18 -4 0 22 62 24 104 2 15 -8 45 -15 66 -15 36 0 70 -23 70 -48 0 -10 44
-18 73 -13 4 0 7 -3 7 -7 0 -5 7 -9 15 -9 8 0 15 4 15 8 0 16 46 13 65 -4 34
-31 55 -40 55 -22 0 25 39 18 46 -9 6 -25 34 -45 34 -25 0 14 -61 99 -71 99
-6 0 -19 18 -81 115 -7 10 -19 25 -25 33 -7 7 -13 19 -13 27 0 7 -3 15 -7 17
-26 11 -124 189 -206 373 -20 44 -47 112 -62 150 -14 39 -32 84 -40 100 -19
42 -31 83 -59 210 -29 131 -45 213 -45 240 -1 11 -6 74 -12 140 -15 157 -15
302 0 450 7 66 15 145 17 175 3 30 10 59 15 65 5 5 9 22 9 38 0 16 16 86 36
156 32 114 47 158 104 316 33 89 166 348 224 434 31 46 56 90 56 97 0 6 15 30
33 52 17 22 43 55 57 74 46 61 294 310 371 372 139 112 151 121 188 141 20 11
61 36 91 56 30 19 87 52 125 73 39 21 75 42 80 46 6 4 69 33 140 65 72 31 135
60 140 64 30 23 250 84 410 116 109 21 128 23 300 36 198 14 535 -5 680 -40
22 -5 65 -14 95 -20 30 -6 78 -18 105 -26 28 -8 84 -24 125 -36 41 -12 79 -26
85 -30 5 -5 15 -9 22 -9 7 0 41 -13 75 -29 35 -15 81 -34 102 -41 37 -12 41
-11 54 9 17 24 32 28 32 7 0 -19 13 -27 29 -17 10 7 10 12 -1 31 -12 20 -11
24 10 37 12 9 22 21 22 29 0 7 23 34 51 61 28 26 78 86 110 134 33 47 73 93
89 103 20 11 30 25 30 42 0 17 5 24 20 24 11 0 20 7 20 15 0 8 5 15 10 15 15
0 12 19 -4 36 -28 27 -46 17 -46 -26 0 -33 -3 -40 -19 -40 -13 0 -21 8 -23 23
-2 17 -10 23 -40 25 -24 2 -38 9 -41 20 -4 15 -5 15 -6 -1 -1 -13 -8 -17 -31
-15 -17 1 -30 8 -30 15 0 18 -17 16 -24 -2 -3 -8 -15 -15 -26 -15 -21 0 -21 3
-17 163 4 131 0 203 -20 377 -13 118 -27 237 -30 263 -8 68 -31 77 -115 47
-132 -48 -326 -137 -438 -203 -41 -24 -90 -52 -109 -63 -19 -10 -53 -34 -77
-51 -24 -18 -67 -49 -96 -70 -78 -54 -161 -125 -270 -231 -103 -101 -121 -108
-131 -50 -18 105 -27 135 -73 235 -8 17 -14 34 -14 38 0 22 -102 214 -124 234
-26 24 -26 24 -42 5 -10 -13 -17 -15 -20 -7 -9 21 -45 63 -55 63 -12 0 -12
-27 1 -32 7 -2 7 -15 0 -37 -7 -26 -7 -37 2 -42 7 -5 9 -11 5 -16 -5 -4 -12
-1 -17 7 -6 11 -14 7 -35 -18 -29 -35 -45 -41 -45 -17 0 23 -16 18 -39 -11
-12 -15 -27 -24 -35 -21 -10 4 -12 0 -9 -14 5 -16 0 -19 -36 -19 -37 0 -41 -2
-41 -25 0 -22 -4 -25 -35 -25 -26 0 -45 -9 -70 -32 -19 -17 -35 -37 -35 -45 0
-7 -4 -13 -10 -13 -5 0 -10 -9 -10 -20 0 -16 -7 -20 -35 -20 -36 0 -50 -7 -88
-48 -12 -13 -28 -20 -38 -17 -11 4 -24 -4 -37 -20 -11 -14 -28 -25 -37 -25
-10 0 -28 -12 -41 -27 -18 -21 -33 -27 -70 -29 -27 -1 -50 -7 -52 -13 -8 -25
-35 -42 -58 -37 -17 5 -24 2 -24 -9 0 -8 -4 -15 -8 -15 -16 0 -72 66 -72 85 0
10 -6 26 -13 34 -18 21 -63 128 -72 171 -4 19 -11 40 -15 45 -4 6 -15 34 -25
63 -11 28 -23 52 -29 52 -12 0 -86 -80 -86 -94 0 -15 -62 -14 -67 1 -3 7 -11
9 -19 6 -8 -3 -14 0 -14 6 0 6 -17 18 -37 26 -21 9 -40 19 -43 24 -3 4 -50 33
-105 64 -55 31 -104 60 -110 65 -41 34 -285 145 -424 193 -97 33 -114 35 -131
14z m1410 -736 c0 -24 -4 -29 -24 -29 -14 0 -28 5 -31 10 -3 6 3 10 13 10 21
0 36 25 25 42 -3 7 -1 8 5 4 7 -4 12 -20 12 -37z m-53 9 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-1747 -818 c0 -22 -4 -40 -10 -40 -5 0 -10
18 -10 40 0 22 5 40 10 40 6 0 10 -18 10 -40z m-82 -567 c-10 -2 -28 -2 -40 0
-13 2 -5 4 17 4 22 1 32 -1 23 -4z m-551 -59 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m948 -3104 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m389 -46 c20 -8 21 -24 2 -24 -8 0 -16 7 -20 15 -6
17 -4 18 18 9z m103 -46 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m38 -28 c3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10 15 10 5 0 12 -4
15 -10z"/>
<path d="M3533 9713 c-7 -3 -13 -11 -13 -18 0 -20 27 -26 35 -8 7 19 -5 32
-22 26z"/>
<path d="M5840 9630 c0 -19 3 -21 12 -12 9 9 9 15 0 24 -9 9 -12 7 -12 -12z"/>
<path d="M4613 9463 c-7 -2 -13 -11 -13 -18 0 -19 34 -11 38 8 4 17 -3 20 -25
10z"/>
<path d="M2863 9079 c-29 -11 -29 -16 2 -45 33 -30 95 -43 95 -20 0 10 -9 16
-25 16 -16 0 -23 4 -19 13 8 22 -28 46 -53 36z"/>
<path d="M3517 9083 c-3 -5 -1 -17 4 -28 7 -17 8 -17 8 8 1 27 -4 34 -12 20z"/>
<path d="M7395 8323 c0 -16 6 -23 20 -23 33 0 45 13 32 31 -19 24 -52 19 -52
-8z"/>
<path d="M6000 8211 c-324 -29 -733 -179 -1005 -369 -27 -20 -63 -45 -80 -56
-16 -12 -32 -23 -35 -26 -3 -3 -23 -18 -45 -35 -21 -16 -47 -36 -55 -45 -216
-204 -362 -363 -394 -430 -3 -8 -34 -55 -66 -105 -33 -49 -80 -133 -106 -185
-25 -52 -52 -107 -60 -122 -7 -14 -14 -32 -14 -38 0 -7 -8 -31 -19 -54 -30
-68 -46 -118 -80 -251 -88 -341 -87 -740 0 -1080 32 -125 57 -201 84 -262 8
-17 15 -35 15 -40 0 -15 132 -274 158 -310 12 -17 22 -34 22 -37 0 -3 16 -29
35 -57 20 -27 40 -61 45 -73 5 -13 35 -52 67 -87 215 -234 262 -279 395 -383
54 -42 100 -76 103 -76 6 0 67 -42 75 -51 3 -3 19 -14 35 -23 17 -9 44 -25 60
-34 70 -42 276 -142 292 -142 4 0 21 -7 37 -15 16 -9 46 -20 65 -24 20 -5 50
-14 66 -20 92 -32 136 -44 190 -51 33 -4 80 -13 105 -20 25 -7 86 -16 135 -19
l90 -6 3 -35 c2 -19 6 -38 10 -42 6 -5 176 -12 235 -9 12 1 17 10 17 34 0 47
15 57 89 57 37 0 98 8 136 16 39 9 93 19 120 23 54 8 151 35 203 57 18 8 40
14 50 14 9 1 35 9 57 20 22 11 46 19 53 20 8 0 26 6 40 14 15 8 70 36 122 61
52 26 113 58 135 71 22 13 47 27 55 31 21 10 106 69 163 115 27 21 53 38 58
38 15 0 345 333 371 375 14 22 37 52 50 66 14 15 28 33 32 40 4 8 33 55 66
104 51 78 121 209 165 310 7 17 22 50 33 75 11 25 22 56 25 70 3 14 13 48 22
75 10 28 22 64 27 80 6 17 16 62 23 100 7 39 17 88 22 110 6 22 17 115 24 208
13 181 1 450 -26 552 -7 28 -16 72 -20 100 -4 27 -13 61 -20 75 -7 14 -16 44
-20 68 -4 24 -13 56 -21 70 -8 15 -14 31 -14 37 0 29 -133 316 -181 390 -10
17 -25 41 -32 55 -65 134 -355 462 -517 585 -117 90 -250 179 -334 224 -33 17
-64 35 -70 39 -29 22 -208 95 -331 136 -236 77 -363 98 -630 101 -121 2 -258
0 -305 -4z m120 -767 l0 -494 -67 1 c-38 0 -104 4 -148 9 -44 5 -129 14 -190
19 -106 9 -194 25 -225 41 -18 10 -16 56 5 96 8 16 15 37 15 47 0 9 9 34 20
54 11 21 20 44 20 51 0 8 6 26 14 40 7 15 29 59 48 97 70 137 112 211 132 230
6 6 23 28 38 50 61 89 174 189 264 234 32 16 62 27 66 24 5 -2 8 -227 8 -499z
m410 430 c73 -55 116 -97 172 -166 51 -63 132 -183 148 -220 6 -13 14 -30 19
-38 5 -8 14 -26 20 -40 6 -14 15 -32 20 -40 12 -19 81 -193 81 -203 0 -5 9
-28 21 -53 33 -73 26 -86 -56 -107 -38 -9 -117 -21 -175 -27 -58 -5 -164 -15
-236 -21 -72 -7 -138 -10 -147 -6 -16 6 -17 46 -17 495 0 269 3 491 6 494 10
11 89 -26 144 -68z m-970 -40 c0 -9 -21 -46 -65 -114 -23 -36 -55 -90 -69
-120 -15 -30 -32 -62 -37 -70 -31 -51 -106 -235 -150 -368 l-24 -74 -40 5
c-57 7 -153 42 -327 121 -31 14 -61 26 -67 26 -10 0 -31 31 -31 47 0 19 190
199 285 269 33 25 67 51 76 58 17 14 80 54 147 93 35 20 156 81 232 116 35 16
70 22 70 11z m1449 -16 c48 -17 242 -116 262 -134 8 -8 18 -14 22 -14 12 0
171 -112 226 -158 30 -26 95 -85 144 -133 69 -67 88 -90 83 -105 -6 -20 -95
-67 -148 -80 -16 -3 -28 -10 -28 -14 0 -4 -17 -12 -37 -18 -21 -6 -76 -24
-123 -41 -104 -38 -118 -38 -130 -3 -6 15 -15 36 -20 47 -5 11 -13 36 -19 55
-14 50 -37 110 -55 145 -9 17 -16 35 -16 40 0 10 -109 230 -133 270 -7 10 -26
39 -43 65 -34 50 -48 90 -31 90 6 0 27 -6 46 -12z m-2364 -793 c6 -2 21 -9 35
-15 14 -5 32 -14 40 -19 8 -5 42 -20 75 -32 33 -13 69 -29 80 -36 11 -7 27
-13 37 -13 10 0 35 -9 55 -20 21 -11 47 -20 58 -20 11 0 43 -10 72 -22 48 -20
53 -25 53 -53 0 -18 -7 -54 -16 -81 -8 -27 -19 -96 -24 -154 -5 -58 -14 -136
-19 -175 -6 -38 -10 -111 -11 -162 0 -50 -4 -103 -10 -117 l-10 -26 -407 2
-408 3 3 95 c2 100 22 210 53 298 11 30 19 64 19 76 0 12 6 30 14 41 7 11 16
34 20 50 8 32 38 101 91 205 97 190 127 225 170 194 11 -8 25 -16 30 -19z
m3300 10 c14 -13 25 -31 25 -40 0 -8 4 -15 10 -15 5 0 18 -16 27 -35 73 -144
103 -208 123 -260 13 -33 28 -73 35 -90 26 -65 55 -161 55 -186 0 -15 7 -46
16 -70 9 -27 16 -84 17 -149 l2 -105 -404 -3 c-236 -1 -409 2 -414 7 -5 5 -11
65 -14 133 -2 67 -11 165 -19 215 -8 51 -14 117 -14 146 0 29 -6 76 -14 103
-19 67 -27 127 -18 139 11 15 80 45 105 45 12 0 39 9 60 20 20 11 44 20 52 20
8 0 36 10 62 22 27 11 62 27 78 33 17 7 66 31 110 53 44 22 84 41 88 41 4 1
19 -10 32 -24z m-2407 -287 c36 -11 270 -35 457 -48 66 -4 121 -9 123 -9 1 -1
1 -137 0 -303 l-3 -303 -390 0 -390 0 -3 94 c-2 57 5 151 18 238 11 79 20 158
20 174 0 38 32 162 44 170 10 8 79 1 124 -13z m1548 14 c18 -11 42 -119 54
-239 6 -59 15 -133 21 -163 6 -30 9 -104 7 -165 l-3 -110 -390 0 -390 0 -3
303 c-1 166 -1 303 0 303 2 1 55 5 118 9 199 13 388 36 535 64 33 6 40 6 51
-2z m-968 -1245 l-3 -302 -105 -7 c-58 -3 -136 -11 -175 -17 -38 -5 -106 -13
-150 -16 -44 -3 -109 -13 -145 -21 -36 -9 -81 -14 -100 -12 l-35 3 -17 80
c-44 205 -75 588 -48 597 5 2 183 2 395 0 l385 -2 -2 -303z m1052 179 c0 -66
-5 -139 -10 -161 -5 -22 -14 -92 -20 -155 -6 -63 -18 -142 -28 -175 -18 -59
-19 -60 -56 -63 -22 -2 -71 4 -110 12 -39 8 -105 18 -146 21 -41 3 -129 13
-195 21 -66 8 -142 15 -170 17 l-50 2 0 300 0 300 380 5 c209 3 386 3 393 1 9
-2 12 -36 12 -125z m-2096 102 c3 -13 6 -66 6 -118 0 -52 5 -126 11 -165 5
-38 14 -115 19 -170 4 -55 15 -125 24 -155 27 -93 23 -108 -31 -131 -25 -11
-67 -24 -92 -30 -25 -5 -48 -13 -51 -17 -3 -4 -23 -13 -45 -19 -77 -22 -135
-45 -135 -54 0 -5 -8 -9 -17 -9 -10 0 -28 -6 -40 -13 -89 -49 -134 -69 -147
-64 -24 9 -71 86 -143 232 -65 131 -72 147 -79 179 -4 16 -13 41 -20 55 -8 14
-14 34 -14 44 0 10 -7 31 -15 46 -8 16 -17 48 -21 72 -3 24 -12 64 -19 89 -7
25 -16 91 -20 148 l-6 102 414 0 415 0 6 -22z m3183 -80 c-3 -57 -13 -134 -22
-173 -26 -109 -55 -207 -70 -240 -7 -16 -16 -41 -20 -55 -13 -50 -124 -276
-171 -347 -39 -60 -67 -68 -111 -33 -14 11 -34 20 -43 20 -10 0 -23 7 -30 15
-7 8 -17 15 -22 15 -5 0 -28 8 -51 19 -23 10 -69 28 -102 41 -33 12 -75 29
-93 36 -18 8 -40 14 -50 14 -9 0 -41 11 -70 25 l-52 24 6 43 c3 24 12 66 20
95 8 28 14 77 14 110 0 32 7 94 14 138 8 44 18 136 21 205 4 69 8 131 10 138
4 9 95 12 416 12 l412 0 -6 -102z m-2137 -1263 c0 -294 -4 -495 -9 -495 -10 0
-82 32 -121 55 -66 37 -182 160 -253 268 -71 107 -187 326 -187 354 0 7 -9 30
-20 50 -11 21 -20 45 -20 53 0 8 -6 27 -14 42 -23 43 -28 84 -14 95 22 17 144
43 205 44 32 0 92 6 133 13 41 8 126 14 188 15 l112 1 0 -495z m505 484 c39
-6 115 -14 170 -19 125 -12 216 -32 228 -51 10 -15 -25 -132 -58 -195 -8 -16
-15 -34 -15 -41 0 -12 -4 -20 -63 -138 -73 -148 -113 -215 -134 -228 -7 -4
-13 -15 -13 -23 0 -8 -46 -61 -102 -117 -76 -76 -120 -111 -170 -136 -36 -18
-71 -30 -77 -26 -8 4 -11 156 -11 496 l0 489 88 0 c48 0 119 -5 157 -11z
m-1386 -185 c11 -31 21 -61 21 -67 0 -11 27 -84 60 -162 10 -22 25 -58 34 -80
9 -22 21 -47 26 -55 5 -8 23 -42 39 -75 30 -60 87 -152 116 -190 17 -21 20
-55 5 -55 -14 0 -57 19 -158 69 -133 66 -146 73 -152 81 -3 3 -27 19 -55 35
-27 16 -54 32 -60 37 -5 5 -34 26 -63 47 -29 22 -80 63 -114 92 -135 119 -190
181 -182 203 10 25 246 133 331 151 18 3 35 11 38 16 4 5 26 9 49 9 43 0 43 0
65 -56z m2143 42 c18 -7 60 -23 93 -35 33 -12 73 -28 88 -36 16 -8 33 -15 38
-15 6 0 39 -13 74 -30 97 -45 96 -55 -16 -165 -104 -100 -230 -205 -248 -205
-6 0 -11 -4 -11 -8 0 -10 -71 -58 -100 -67 -11 -3 -20 -10 -20 -14 0 -4 -57
-35 -127 -70 -71 -34 -141 -69 -156 -77 -52 -26 -61 -5 -22 56 61 98 95 159
95 170 0 5 4 10 9 10 5 0 12 13 16 29 3 16 13 35 21 42 8 6 14 16 14 22 0 10
30 78 61 139 10 20 19 44 19 53 0 8 5 23 10 33 11 18 48 121 57 160 4 17 12
22 39 22 18 0 48 -6 66 -14z"/>
<path d="M7540 8211 c0 -12 28 -22 35 -12 3 5 -2 12 -11 15 -21 8 -24 8 -24
-3z"/>
<path d="M7646 8193 c-12 -12 -5 -23 14 -23 11 0 20 4 20 9 0 11 -26 22 -34
14z"/>
<path d="M7795 8190 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7751 8151 c-10 -6 -10 -13 -2 -28 14 -28 5 -34 -27 -17 -35 18 -38
12 -9 -20 29 -31 47 -33 47 -6 0 11 7 20 16 20 11 0 14 6 9 23 -10 31 -18 38
-34 28z"/>
<path d="M7804 8055 c22 -17 36 -19 36 -6 0 10 -24 21 -43 21 -7 0 -3 -7 7
-15z"/>
<path d="M7870 8056 c0 -25 -30 -47 -54 -40 -18 6 -17 3 7 -20 34 -31 47 -33
47 -6 0 11 7 20 15 20 18 0 20 -31 4 -47 -9 -9 8 -31 65 -88 41 -42 99 -106
128 -141 28 -36 58 -62 64 -60 8 3 11 0 8 -8 -6 -15 33 -66 50 -66 7 0 19 12
27 28 7 15 21 38 31 51 10 13 18 32 18 42 0 11 7 19 15 19 9 0 15 9 15 25 0
22 -4 25 -34 25 -19 0 -38 5 -41 10 -3 6 -2 10 3 10 5 0 7 11 4 25 -4 21 -10
25 -38 25 -20 0 -34 -5 -34 -12 0 -10 -3 -9 -9 1 -7 10 -10 10 -16 1 -4 -7 -1
-16 6 -21 9 -6 4 -7 -16 -3 -46 11 -61 42 -25 51 30 8 25 33 -6 33 -19 0 -25
-4 -20 -15 6 -18 -10 -20 -20 -3 -4 6 -2 14 4 16 7 2 12 15 12 28 0 21 -4 24
-40 24 -29 0 -40 -4 -40 -15 0 -19 -60 -21 -78 -3 -18 18 -15 36 6 34 25 -2
16 31 -18 66 -32 32 -40 34 -40 14z"/>
<path d="M8356 7925 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M8320 7845 c0 -15 4 -24 10 -20 6 3 10 12 10 20 0 8 -4 17 -10 20 -6
4 -10 -5 -10 -20z"/>
<path d="M4096 4251 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M4160 4245 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M8412 2596 c-30 -7 -80 -27 -112 -44 -59 -30 -59 -30 -77 -10 -41 46
-57 48 -343 48 -277 0 -292 -2 -335 -41 -20 -18 -21 -18 -49 6 -16 14 -43 27
-60 30 -17 3 -638 4 -1381 1 -742 -2 -1363 -4 -1380 -3 -30 0 -1597 0 -1625 0
-8 -1 -120 1 -248 4 -226 4 -234 3 -272 -18 -39 -22 -40 -22 -61 -3 -20 18
-43 19 -589 19 l-568 0 -27 -25 c-57 -53 -55 -30 -53 -657 2 -318 4 -482 5
-365 4 236 -4 215 79 212 39 -1 42 1 48 31 3 17 6 115 6 218 0 102 3 243 7
314 l6 127 239 0 238 0 0 -105 0 -104 -97 -3 -98 -3 1 -100 1 -100 86 -3 c61
-2 87 -7 90 -17 5 -11 10 -145 8 -195 -1 -12 -15 -14 -78 -12 -83 3 -117 -8
-96 -29 7 -7 47 -14 94 -15 53 -2 85 -8 91 -16 7 -10 8 -10 6 2 -1 8 3 24 9
35 11 20 42 194 83 470 11 77 23 152 26 168 l6 27 199 0 c198 0 199 0 203 -22
3 -13 17 -97 31 -188 23 -151 34 -213 67 -385 13 -71 25 -98 41 -92 7 2 17 -3
24 -12 11 -14 12 -13 13 9 0 18 3 21 9 12 13 -21 21 36 21 147 0 53 3 194 7
314 l6 217 178 0 c177 0 179 0 184 -22 2 -13 19 -114 36 -225 17 -112 35 -203
39 -203 4 0 19 89 34 198 15 108 29 209 32 225 l6 27 177 0 178 0 6 -137 c4
-75 7 -209 7 -298 0 -177 8 -265 21 -243 7 10 9 10 9 1 0 -8 13 -13 30 -13 25
0 30 4 34 33 8 49 15 262 16 470 l0 187 139 0 139 0 4 -62 c2 -35 5 -171 6
-303 3 -255 9 -336 23 -314 6 10 9 11 9 2 0 -22 27 -14 34 10 3 12 9 167 12
345 l7 323 141 -3 141 -3 2 -270 c3 -322 10 -442 26 -426 6 6 23 11 38 11 19
0 28 6 33 23 3 12 9 167 13 345 l6 322 117 0 117 0 73 -224 c41 -124 78 -222
82 -220 4 3 8 104 8 225 l0 220 118 -3 117 -3 6 -340 c3 -187 6 -341 7 -342 6
-8 31 -1 35 10 4 10 6 10 6 0 1 -19 38 -15 46 5 4 9 10 164 13 345 l5 327 143
0 143 0 3 -338 c3 -307 5 -340 21 -356 9 -10 17 -13 17 -7 0 6 6 11 13 11 18
0 27 161 27 467 l0 223 145 0 145 0 1 -312 c0 -172 4 -333 8 -358 6 -30 9 -37
11 -20 l2 25 8 -25 c4 -14 8 -19 9 -12 1 8 24 12 81 12 78 0 80 0 87 28 4 15
7 122 8 237 l0 210 -82 3 -83 3 0 104 0 105 315 0 315 0 0 -105 0 -105 -85 0
-85 0 0 -219 c0 -148 4 -222 11 -227 6 -3 9 -14 6 -23 -3 -14 8 -16 96 -14 54
1 102 5 105 8 4 4 9 159 10 346 l4 339 234 0 234 0 0 -105 0 -105 -90 0 -90 0
0 -105 0 -105 84 0 85 0 3 -102 c2 -57 2 -107 0 -113 -1 -6 -35 -9 -77 -9 -44
1 -78 -3 -82 -9 -4 -6 -1 -13 5 -15 7 -2 13 -16 13 -30 1 -16 3 -20 6 -10 6
25 34 30 125 24 60 -5 84 -3 91 6 6 10 7 8 4 -4 -10 -28 5 -29 22 0 13 23 16
79 19 355 l3 327 145 0 144 0 1 -327 c1 -181 5 -341 9 -358 4 -16 8 -23 9 -15
1 13 2 13 11 0 7 -11 10 -12 10 -2 0 10 19 13 84 10 46 -2 82 0 80 4 -2 4 0 9
6 13 6 3 11 -4 11 -17 1 -14 5 -7 10 17 4 22 8 183 8 358 l1 317 235 0 235 0
0 -105 0 -105 -90 0 -90 0 0 -105 0 -105 85 0 85 0 0 -112 0 -113 -75 0 c-68
-1 -107 -12 -77 -22 6 -3 13 -17 13 -31 1 -21 2 -23 6 -7 6 22 23 45 23 30 0
-6 31 -10 70 -11 38 -1 67 3 64 7 -3 5 0 9 5 9 6 0 11 -4 11 -10 0 -16 49 -7
57 10 3 8 9 119 13 245 5 203 8 235 27 275 28 61 74 107 142 139 51 24 67 26
176 26 134 0 179 -14 247 -73 61 -55 80 -115 86 -279 l5 -143 -146 0 -146 0
-3 149 c-2 82 -5 151 -8 154 -15 14 -48 15 -66 3 -18 -14 -19 -28 -18 -243 0
-233 3 -285 17 -276 4 2 7 -2 7 -9 0 -7 6 -4 12 7 11 19 11 19 19 0 7 -18 8
-18 8 -2 1 10 5 16 10 13 5 -3 11 4 14 17 6 22 9 22 154 21 103 0 148 -4 151
-12 2 -7 8 -10 13 -6 5 3 9 -2 9 -11 0 -11 5 -15 14 -12 8 3 16 1 19 -6 2 -7
10 -1 17 13 10 18 16 90 21 250 4 139 12 238 19 260 45 124 152 189 313 189
152 1 252 -50 303 -156 27 -55 28 -61 35 -302 4 -135 8 -247 8 -248 1 -2 18
-3 40 -3 35 0 39 3 45 31 3 17 6 173 6 345 l0 314 184 0 183 0 27 -172 c42
-267 49 -304 54 -289 2 7 19 113 36 235 l31 221 181 3 182 2 5 -322 c2 -178 8
-333 12 -345 5 -17 15 -23 36 -23 16 0 29 5 29 13 1 6 7 1 15 -13 8 -14 14
-19 15 -12 0 6 9 12 20 12 20 0 20 7 20 365 0 395 0 394 -55 445 l-27 25 -481
2 c-308 2 -493 -1 -513 -7 -17 -6 -41 -21 -53 -34 l-22 -24 -43 24 c-67 38
-133 54 -236 61 -153 9 -272 -28 -371 -116 l-46 -40 -19 20 c-32 36 -96 75
-169 104 -58 23 -88 28 -180 31 -71 2 -129 -1 -163 -10z m125 -838 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M9299 2259 c-20 -20 -20 -28 -17 -247 2 -125 8 -235 12 -244 5 -10
19 -18 32 -18 38 0 45 53 42 296 -2 178 -6 216 -18 224 -21 14 -28 12 -51 -11z"/>
<path d="M2187 2074 c-23 -282 -21 -338 7 -315 11 9 15 9 20 -1 10 -20 -3 347
-12 373 -7 17 -10 3 -15 -57z"/>
<path d="M10022 1905 c0 -38 3 -90 7 -115 8 -44 9 -44 9 20 0 36 -3 88 -8 115
l-7 50 -1 -70z"/>
<path d="M10366 1862 c-4 -57 -6 -106 -3 -109 10 -10 18 62 15 137 l-3 75 -9
-103z"/>
<path d="M3220 1944 c0 -5 -5 -38 -10 -74 -5 -37 -6 -79 -1 -95 6 -23 9 -8 15
68 4 55 5 102 2 105 -3 3 -6 1 -6 -4z"/>
<path d="M2867 1860 c6 -116 11 -131 11 -35 0 44 -3 89 -7 100 -4 11 -6 -18
-4 -65z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
